/*--------------------------------------------------------------
NAV  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Resize
- Scroll
- Arrows
- Move
--------------------------------------------------------------*/



class Nav {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor({up, down, reset}) {
		this.int = 0;
		this.up = up;
		this.down = down;
		this.reset = reset;
		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.resize();
		window.on('resize', _ => this.resize());
		window.on('mousewheel', e => this.scroll(e));
		window.on('DOMMouseScroll', e => this.scroll(e));
		window.on('keydown', e => this.arrows(e));
	}



	/*--------------------------------------------------------------
	-	Resize
	--------------------------------------------------------------*/
	resize () {
		this.tooSmall = window.innerWidth < 1200;
		if (this.tooSmall) {
			document.body.classList.add('scrollable');
			this.reset();
		} else {
			document.body.classList.remove('scrollable');
		}
	}



	/*--------------------------------------------------------------
	-	Scroll
	--------------------------------------------------------------*/
	scroll (e) {
		if (this.tooSmall) return;
		e.preventDefault();
		var delta = e.wheelDelta || -e.detail;
		delta = delta > 0 ? -1 : 1;
		this.int += delta;
		if(this.int > 5)
			this.move(1);
		else if(this.int < -5)
			this.move(-1);
	}



	/*--------------------------------------------------------------
	-	Arrows
	--------------------------------------------------------------*/
	arrows (e) {
		if (e.keyCode == 40) {
			e.preventDefault();
			this.move(-1);
		} else if (e.keyCode == 38) {
			e.preventDefault();
			this.move(1);
		}
	}



	/*--------------------------------------------------------------
	-	Move
	--------------------------------------------------------------*/
	move (delta) {
		this.int = 0;
		if(delta < 0 && this.up)
			this.up();
		else if(delta > 0 && this.down)
			this.down();
	}



}
