/*--------------------------------------------------------------
MAGNET  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Reset
- Go2
- Reset btn
- Reset slides
--------------------------------------------------------------*/



class Magnet {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (slides, nav, duration) {
		this.slides = $$.all(slides);
		this.length = this.slides.length;
		this.btns = $$.id(nav).tags('button');
		this.duration = duration;
		this.pending = false;
		this.index = 0;
		this.bind();
		this.go2(0);
		this.nav = new Nav({
			up: () => this.go2(this.index-1),
			down: () => this.go2(this.index+1),
			reset: () => this.reset(),
		});
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.btns.foreach((btn, i) => btn.onclick = () => this.go2(i));
	}



	/*--------------------------------------------------------------
	-	Reset
	--------------------------------------------------------------*/
	reset () {
		this.slides.foreach(slide => {
			slide.classList.add('enter');
			slide.classList.remove('off');
		});
	}



	/*--------------------------------------------------------------
	-	Go2
	--------------------------------------------------------------*/
	go2 (i) {
		if (!this.slides[i] || this.pending) return;
		this.pending = true;
		this.slides[i].classList.add('enter');
		this.slides[i].classList.remove('off');
		this.index = i;
		this.reset_btns(i);
		if (i < this.length - 1) {
			this.slides[this.length - 1].classList.remove('enter');
			this.slides[this.length - 1].classList.add('off');
		}
		new Timeout(this.duration, _ => {
			this.pending = false;
			this.reset_slides(i);
		});
	}



	/*--------------------------------------------------------------
	-	Reset btns
	--------------------------------------------------------------*/
	reset_btns (i) {
		this.btns.foreach((btn, o) => {
			if (o == i)
			btn.classList.add('on');
			else
			btn.classList.remove('on');
		});
		if (i === 3)
			this.btns[2].classList.add('on');
	}



	/*--------------------------------------------------------------
	-	Reset slides
	--------------------------------------------------------------*/
	reset_slides (i) {
		if (this.nav.tooSmall) return;
		this.slides.foreach((slide, o) => {
			if (i < this.slides.length - 1) {
				if (o == i)
					slide.classList.remove('enter');
				else
					slide.classList.add('off');
			}
		});
	}



}
