/*--------------------------------------------------------------
ROUTING  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Pages
- Init
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Pages
--------------------------------------------------------------*/
const page = {
	common: {
		init () {
			// ajax.setGraphqlURL('http://localhost:7913/graphql');
			//ajax.setGraphqlURL('http://vse.dev.adelios.fr/graphql');
			ajax.setGraphqlURL('https://v-st-eustache.com/graphql');
			$$.id('burger').onclick = () =>	$$.tags('header')[0].classList.toggle('open');
			new Newsletter('newsletter', 'btn_news');
		},
		end () {
			// window.off('mousewheel');
			// window.off('DOMMouseScroll');
			// window.off('keydown');
		}
	},
	home () {
		// new Player();
	},
	philo () {
		new Magnet('.magnet, footer', 'nav_philo', 1000);
		$$.all('.slideshow').forEach(el => new Slideshow(el, 4000));
	},
	savoir () {
		new Magnet('.magnet, footer', 'nav_savoir', 1000);
	},
	saisons () {
		new Roue('roue', '.mois', 1000);
	},
	producteurs () {
		// this.world_map = new WorldMap({
		// 	id: 'world_map',
		// 	france_map_id: 'france_map',
		// 	france_path_id: 'cFR',
		// 	tooltip_id: 'tooltip_world',
		// });
		this.france_map = new FranceMap({
			id: 'france_map',
			world_map_id: 'world_map',
			tooltip_id: 'tooltip_france',
		});
	},
	fiche () {
		new Fiche({
			id: 'dispo_produits',
			producer_id: '.producer_id',
			months: '.months',
			products: '.products',
			list: '.list',
		});
	},
};



/*--------------------------------------------------------------
	Init
--------------------------------------------------------------*/
page.common.init();
if (page[document.body.id])
	page[document.body.id]();
