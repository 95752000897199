/*--------------------------------------------------------------
MAP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Show / Hide / is hidden
- Init paths
- Bind
- Show Tooltip
- Hide Tooltip
- Get Tooltip Offset
- Zoom
- Center during zoom
- Pan
- Map CSS
--------------------------------------------------------------*/



class MapProducteurs {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({id, query, tooltip_id}) {
		// Map
		this.scale = 1;
		this.map = $$.id(id);
		this.map_group = this.map.firstChild;
		this.paths = this.map_group.childNodes;
		this.transition_duration = 300;

		// Zoom
		this.zoom_factor = 2;
		this.zoom_limits = {
			min: 1,
			max: 2,
		}
		this.btns_zoom_wrap = $$.id('zoom');
		this.btns_zoom = this.btns_zoom_wrap.getAll('button');

		// Pan
		this.moving = false;
		this.panable = false;
		this.x_start = 0;
		this.y_start = 0;
		this.offset = {
			x: 0,
			y: 0,
		};

		// Tooltip
		this.tooltip = $$.id(tooltip_id);

		this.init_paths(query);
		this._bind();
	}



	/*--------------------------------------------------------------
	-	Show / Hide / is hidden
	--------------------------------------------------------------*/
	show () {
		this.reset_zoom();
		this.map.classList.remove('off');
	}
	hide () {
		this.map.classList.add('off');
	}
	get is_hidden () {
		return this.map.classList.contains('off');
	}



	/*--------------------------------------------------------------
	-	Init paths
	--------------------------------------------------------------*/
	init_paths (query) {

		// We get all areas (countries / departments)
		ajax.graphql({query}).then(({data: {areas}, errors}) => {

			// debug errors
			if(errors) {
				console.error(errors);
			}

			// We stock all areas in one object with the SVG ID as the key
			this.areas = {};
			areas.foreach(area => {
				this.areas[area.svg_id] = area;
			});

			// Areas that are not in the DB are disabled
			this.paths.foreach(path => {
				if (!this.areas.hasOwnProperty(path.id)) {
					path.setAttribute('class', 'off');
				}
			});

		});

	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	_bind () {
		// Zoom
		this.btns_zoom_wrap.on('mouseenter', _ => this.will_change = true);
		this.btns_zoom_wrap.on('mouseleave', _ => this.will_change = false);
		this.btns_zoom.foreach(btn => {
			btn.on('click', _ => this.zoom(btn.className));
		});

		// Pan
		const pan = e => {
			this.moving = true;
			this.pan(e);
		};
		const up = _ => {
			this.will_change = false;
			window.off('mousemove', pan);
			window.off('mouseup', up);
		};
		this.map.addEventListener('mousedown', ({clientX: x, clientY: y}) => {
			this.moving = false;
			this.x_start = x - this.offset.x;
			this.y_start = y - this.offset.y;
			this.will_change = true;
			window.on('mousemove', pan);
			window.on('mouseup', up);
		});
	}



	/*--------------------------------------------------------------
	-	Show Tooltip
	--------------------------------------------------------------*/
	show_tooltip () {
		this.tooltip.classList.add('on');
	}



	/*--------------------------------------------------------------
	-	Hide Tooltip
	--------------------------------------------------------------*/
	hide_tooltip () {
		this.tooltip.classList.remove('on');
	}



	/*--------------------------------------------------------------
	-	Get Tooltip Offset
	--------------------------------------------------------------*/
	get_tooltip_offset ({tail}) {
		let x = this.tooltip.clientWidth / 2;
		let y = tail + this.tooltip.clientHeight + window.scrollY;
		return {x, y};
	}



	/*--------------------------------------------------------------
	-	Zoom
	--------------------------------------------------------------*/
	zoom (type) {
		// Do nothing if map hidden
		if (this.is_hidden === true) return;

		// First the scale (abort if limits)
		if (type === 'plus' &&
			this.scale * this.zoom_factor <= this.zoom_limits.max) {
			this.scale = this.scale * this.zoom_factor;
		} else if (type === 'minus' &&
			this.scale / this.zoom_factor >= this.zoom_limits.min) {
			this.scale = this.scale / this.zoom_factor;
		} else {
			return;
		}

		// Unable buttons at limits
		if (this.scale === this.zoom_limits.min) {
			this.btns_zoom_wrap.className = 'min';
		} else if (this.scale === this.zoom_limits.max) {
			this.btns_zoom_wrap.className = 'max';
		} else {
			this.btns_zoom_wrap.className = '';
		}

		// Translate after zooming
		let {x, y} = this.center_zoom();
		this.offset.x = x;
		this.offset.y = y;

		// Make transitable then transform
		this.transition = true;
		this._transform();

		// If not zoomed, center map and prevent pan
		if (this.scale === 1) {
			this.panable = false;
		} else {
			this.panable = true;
		}

		// Cancel transition
		setTimeout(_ => this.transition = false, this.transition_duration);
	}



	/*--------------------------------------------------------------
	-	Hide / Show zoom
	--------------------------------------------------------------*/
	hide_zoom () {
		this.btns_zoom_wrap.classList.add('hidden');
	}
	show_zoom () {
		this.btns_zoom_wrap.classList.remove('hidden');
	}



	/*--------------------------------------------------------------
	-	Reset zoom
	--------------------------------------------------------------*/
	reset_zoom () {
		this.scale = 1;
		this.btns_zoom_wrap.className = 'min';
		let {x, y} = this.center_zoom();
		this.offset.x = x;
		this.offset.y = y;
		this._transform();
	}



	/*--------------------------------------------------------------
	-	Center during zoom
	--------------------------------------------------------------*/
	center_zoom () {
		let rect = this.map_group.getBoundingClientRect();
		let a = {
			x: window.innerWidth / 2 - rect.left,
			y: window.innerHeight / 2 - rect.top,
		};
		let b = {
			x: a.x * this.scale,
			y: a.y * this.scale,
		};
		console.log('--------');
		console.log(rect.top);
		console.log(a.y);
		console.log(b.y);
		console.log('diff', a.y - b.y);
		return {
			x: a.x - b.x,
			y: a.y - b.y,
		};
	}



	/*--------------------------------------------------------------
	-	Pan -> the limit system isn't the best but it works... could be simplified
	--------------------------------------------------------------*/
	pan ({clientX: x, clientY: y}) {
		if (this.panable === false) return;

		// Hide tooltip when paning
		this.hide_tooltip();

		// All the limit system not to pan out of window
		let limit_factor = 4;
		let {width, height} = this.map_group.getBoundingClientRect();
		let svg_offset = {
			x: (window.innerWidth - width / this.scale) / 2,
			y: (window.innerHeight - height / this.scale) / 2,
		};
		let min = {
			x: (window.innerWidth / limit_factor) - svg_offset.x,
			y: (window.innerHeight / limit_factor) - svg_offset.y,
		};
		let max = {
			x: window.innerWidth - (window.innerWidth / limit_factor) - width - svg_offset.x,
			y: window.innerHeight - (window.innerHeight / limit_factor) - height - svg_offset.y,
		};

		this.offset.x = Math.min(Math.max(x - this.x_start, max.x), min.x);
		this.offset.y = Math.min(Math.max(y - this.y_start, max.y), min.y);

		this._transform();
	}



	/*--------------------------------------------------------------
	-	Map CSS
	--------------------------------------------------------------*/
	set will_change (bool) {
		this.map_group.style.willChange = bool ? 'transform' : 'auto';
	}
	set transition (bool) {
		this.map_group.style.transition = bool ? `transform ${this.transition_duration / 1000}s` : 'none';
	}
	_transform () {
		// Method that moves / scales the map based on this.offset and this.scale
		let transform = `translate(${this.offset.x}px, ${this.offset.y}px) scale(${this.scale})`;
		this.map_group.style.webkitTransform = transform;
		this.map_group.style.transform = transform;
	}



}
