/*--------------------------------------------------------------
AJAX  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Headers
- Open
- Serialize
- Get
- Post
- Graphql
- Upload
- Promise
--------------------------------------------------------------*/



let ajax = {



	/*--------------------------------------------------------------
	-	Headers
	--------------------------------------------------------------*/
	headers: {
		default: 'application/x-www-form-urlencoded',
		json: 'application/json',
	},



	/*--------------------------------------------------------------
	-	Open
	--------------------------------------------------------------*/
	open ({method, url, data, type = 'default', formdata = false}) {
		this.xhr = new XMLHttpRequest();
		this.xhr.open(method, url);
		if (!formdata) {
			this.xhr.setRequestHeader('Content-type', this.headers[type]);
		}
		this.xhr.setRequestHeader('Accept', 'application/json');
		this.xhr.responseType = type == 'json' ? 'json' : 'text';
		this.xhr.send(data);
		return this.promise;
	},



	/*--------------------------------------------------------------
	-	Serialize
	--------------------------------------------------------------*/
	serialize (params) {
		let url = [];
		for (let key in params) {
			url.push(key + '=' + encodeURIComponent(params[key]));
		}
		return url.join('&');
	},



	/*--------------------------------------------------------------
	-	Get
	--------------------------------------------------------------*/
	get (url, data) {
		return this.open({
			method: 'GET',
			url: url + '?' + this.serialize(data),
		});
	},



	/*--------------------------------------------------------------
	-	Post
	--------------------------------------------------------------*/
	post (url, data) {
		let formdata = (data.constructor.name == 'FormData');
		data = formdata ? data : this.serialize(data);
		return this.open({
			method: 'POST',
			url,
			data,
			formdata,
		});
	},



	/*--------------------------------------------------------------
	-	Graphql
	--------------------------------------------------------------*/
	setGraphqlURL (url) {
		this.graphqlURL = url;
	},
	graphql ({query, variables}) {
		return this.open({
			method: 'POST',
			url: this.graphqlURL,
			type: 'json',
			data: JSON.stringify({query, variables}),
		});
	},



	/*--------------------------------------------------------------
	-	Upload
	--------------------------------------------------------------*/
	upload (url, file, name) {
		this.formData = new FormData();
		this.formData.append(name, file, file.name);
		return this.open('POST', url, this.formData, true);
	},



	/*--------------------------------------------------------------
	-	Promise
	--------------------------------------------------------------*/
	promise: {
		then (success) {
			ajax.xhr.onreadystatechange = function () {
				if (this.readyState == 4) {
					if (this.status == 200)
						success(this.response);
					else
						console.error(this.response);
				}
			};
		}
	},



};
