/*--------------------------------------------------------------
POPIN  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Popin
- Months
- Fiches
--------------------------------------------------------------*/

// Change to "on" to activate the popin

if (window.location.pathname == "/fr/") {
  var pop = document.getElementById("popin");
  pop.classList.add("off");
}

/*--------------------------------------------------------------
	Popin
--------------------------------------------------------------*/
var popin = {
  el: $$.id("popin"),
  init() {
    let lang = document.documentElement.lang;
    let d = new Date();
    let m = d.getMonth();
    let month = months[lang][m];
    this.el.get("strong").textContent = month;
    let file_name = month.toLowerCase();
    file_name = file_name.replace("é", "e");
    file_name = file_name.replace("û", "u");
    this.el.get("a").href = `/fiches/${file_name}.pdf`;
  },
  open() {
    this.init();
    this.el.className = "on";
  },
  close() {
    this.el.className = "";
  },
};

/*--------------------------------------------------------------
	Months
--------------------------------------------------------------*/
const months = {
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};
