/*--------------------------------------------------------------
MAP > WORLD  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Open Tooltip
- Activate Country
- Reset countries
- Change Tooltip
- Position Tooltip
- Detect boundaries
--------------------------------------------------------------*/



class WorldMap extends MapProducteurs {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({id, france_map_id, france_path_id, tooltip_id}) {
		// Init
		let query = `{
			areas: countries {
				name: country_name {
					fr
					en
				}
				desc (limit: 90) {
					fr
					en
				}
				url {
					fr
					en
				}
				img
				svg_id
			}
		}`;
		super({id, query, tooltip_id});
		this.france_map = $$.id(france_map_id);
		this.france_path = $$.id(france_path_id);

		// Tooltip
		this.tooltip_title = this.tooltip.get('h2');
		this.tooltip_desc = this.tooltip.get('p');
		this.tooltip_link = this.tooltip.get('a');
		this.tooltip_img = this.tooltip.get('img');

		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		// Go 2 France
		this.france_path.onclick = _ => {
			this.hide();
			page.france_map.show();
			this.hide_tooltip();
		};

		// Open Tooltip on click on country
		const open_tooltip = e => this.open_tooltip(e);
		window.on('click', open_tooltip);

		// Reposition tooltip on resize
		window.on('resize', _ => this.position_tooltip());
	}



	/*--------------------------------------------------------------
	-	Open Tooltip
	--------------------------------------------------------------*/
	open_tooltip (e) {
		// Do nothing if the map is hidden
		if (this.is_hidden) return;

		// Activate country if click on enabled country other than the active one
		let el = e.target;
		if (el.nodeName === 'path' && el.getAttribute('class') !== 'off' && this.moving === false) {
			this.activate_country(el);
		}

		// Reset countries if click on water or disabled country
		else if (!this.tooltip.contains(el) && el !== this.tooltip) {
			this.reset_countries();
			this.hide_tooltip();
		}
	}



	/*--------------------------------------------------------------
	-	Activate Country
	--------------------------------------------------------------*/
	activate_country (path) {
		// Do nothing if country already enabled
		if (path.getAttribute('class') === 'on') return;

		// Set only this country active
		this.reset_countries();
		this.current_path = path;
		path.setAttribute('class', 'on');

		// If no country active, we show tooltip right now. Else we wait for the tooltip to disappear before showing it again
		let timeout = this.tooltip.classList.contains('on') ? 200 : 0;
		this.hide_tooltip();
		new Timeout(timeout, _ => {
			this.change_tooltip();
			this.position_tooltip();
			this.show_tooltip();
		});
	}



	/*--------------------------------------------------------------
	-	Reset countries
	--------------------------------------------------------------*/
	reset_countries () {
		this.paths.foreach(path => {
			if (path.getAttribute('class') != 'off') {
				path.setAttribute('class', '');
			}
		});
	}



	/*--------------------------------------------------------------
	-	Change Tooltip
	--------------------------------------------------------------*/
	change_tooltip () {
		let lang = document.documentElement.lang;
		let {name, desc, img, url} = this.areas[this.current_path.id];
		this.tooltip_title.textContent = name[lang];
		this.tooltip_desc.textContent = desc[lang];
		this.tooltip_link.href = url[lang];
		this.tooltip_img.src = '/img/producteurs/thumbs/' + img;
		this.tooltip_img.alt = name[lang];
	}



	/*--------------------------------------------------------------
	-	Position Tooltip
	--------------------------------------------------------------*/
	position_tooltip () {
		// If no country has been activated yet, no need to position tooltip
		if (!this.current_path) return;

		let {x: offset_x, y: offset_y} = this.get_tooltip_offset({tail: 15});
		let {top, left, width, height} = this.current_path.getBoundingClientRect();
		let x = left + (width / 2) - offset_x;
		let y = top + (height / 2) - offset_y;
		let boundary = this.at_boundaries(x);
		if (boundary) {
			this.tooltip.classList.add(boundary.side);
			x += boundary.offset;
		} else {
			this.tooltip.classList.remove('left');
			this.tooltip.classList.remove('right');
		}
		this.tooltip.style.top = y + 'px';
		this.tooltip.style.left = x + 'px';
	}



	/*--------------------------------------------------------------
	-	Detect boundaries
	--------------------------------------------------------------*/
	at_boundaries (x) {
		let offset = this.tooltip.clientWidth / 2 - 30;
		if (x < 40) {
			return {
				offset: offset,
				side: 'left',
			};
		} else if (x + this.tooltip.clientWidth > window.innerWidth - 60) {
			return {
				offset: -offset,
				side: 'right',
			};
		} else {
			return false;
		}
	}



}
