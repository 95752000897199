
var Legale = {
    el: $$.id("notice"),
    init() {
        let lang = document.documentElement.lang;
        let d = new Date();
        let m = d.getMonth();
        let month = months[lang][m];
        let file_name = month.toLowerCase();
        file_name = file_name.replace("é", "e");
        file_name = file_name.replace("û", "u");
      },
    popup(){
        this.init();
        this.el.className = "on";
    },
    hide() {
        this.el.className = "";
      },
}

const months = {
    fr: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };