/*--------------------------------------------------------------
ROUE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Resize
- Go2
- Hover in / out
- Scroll
- Animate
--------------------------------------------------------------*/



class Roue {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (id, mois, duration) {
		this.wrapper = $$.id(id);
		this.labels = this.wrapper.tags('label');
		this.btns = this.wrapper.tags('input');
		this.imgs = this.wrapper.tags('img');
		this.title = this.wrapper.tags('div')[0];
		this.months = $$.all(mois);
		this.duration = duration;
		this.main = $$.first('.main');
		this.index = 0;
		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.labels.foreach((label, i) => {
			this.btns[i].onclick = _ => this.go2(this.btns[i]);
			label.onmouseover = _ => this.mouseon(label.firstChild);
			label.onmouseout = _ => this.mouseout();
		});
		this.resize();
	}



	/*--------------------------------------------------------------
	-	Resize
	--------------------------------------------------------------*/
	resize () {
		let current = $$.first('.mois.on');
		let header = $$.tags('header')[0];
		return current.getBoundingClientRect().top - header.clientHeight - 50;
	}



	/*--------------------------------------------------------------
	-	Go2
	--------------------------------------------------------------*/
	go2 (input) {
		this.title.textContent = input.getAttribute('data-long');
		this.months.foreach((month, i) => {
			if (i == input.value) {
				month.classList.add('on');
				this.imgs[i].classList.add('on');
			} else {
				month.classList.remove('on');
				this.imgs[i].classList.remove('on');
			}
		});
		this.index = input.value;
		this.animate();
	}



	/*--------------------------------------------------------------
	-	Hover in / out
	--------------------------------------------------------------*/
	mouseon (input) {
		this.title.textContent = input.getAttribute('data-long');
		this.imgs.foreach((img, i) => img.className = i == input.value ? 'on' : '');
	}
	mouseout () {
		this.title.textContent = this.btns[this.index].getAttribute('data-long');
		this.imgs.foreach((img, i) => img.className = i == this.index ? 'on' : '');
	}



	/*--------------------------------------------------------------
	-	Scroll
	--------------------------------------------------------------*/
	disableScroll () {
		window.on('mousewheel', this.prevent);
		window.on('DOMMouseScroll', this.prevent);
	}
	enableScroll () {
		window.off('mousewheel', this.prevent);
		window.off('DOMMouseScroll', this.prevent);
	}
	prevent (e) {
		e.preventDefault();
	}



	/*--------------------------------------------------------------
	-	Animate
	--------------------------------------------------------------*/
	animate () {
		let offset = this.resize();
		this.disableScroll();
		this.main.style.transition = (this.duration/1000) + 's cubic-bezier(.7, 0, .3, 1)';
		this.main.style.transform = `translateY(-${offset}px)`;
		new Timeout(this.duration, _ => {
			let newScroll = window.pageYOffset + offset;
			window.scrollTo(0, newScroll);
			this.main.style.transition = `none`;
			this.main.style.transform = `none`;
			this.enableScroll();
		});
	}


}
