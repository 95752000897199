/*--------------------------------------------------------------
NEWSLETTER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
--------------------------------------------------------------*/



class Newsletter {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (form, btn) {
		this.form = $$.id(form);
		this.input = this.form.elements[0];
		this.btn = $$.id(btn);
		this.success = this.form.get('.success');
		this.error = this.form.get('.error');
		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.btn.onclick = _ => {
			this.form.classList.toggle('on');
			setTimeout(_ => this.input.focus(), 100);
		};
		this.form.onsubmit = e => this.submit(e);
		this.success.onclick = e => {
			this.form.classList.remove('on');
			e.target.classList.remove('on');
		};
			this.error.onclick = e => {
			this.form.classList.remove('on');
			e.target.classList.remove('on');
		};
	}



	/*--------------------------------------------------------------
	-	Submit
	--------------------------------------------------------------*/
	submit (e) {
		e.preventDefault();

		let variables = {email: this.input.value};
		let query = `
			mutation ($email: String!) {
				subscribe (email: $email) {
					id
				}
			}
		`;

		ajax.graphql({query, variables}).then(res => {
			if (res.errors) {
				this.error.classList.add('on');
			} else {
				this.success.classList.add('on');
				this.form.reset();
			}
		});

	}



}
