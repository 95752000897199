/*--------------------------------------------------------------
SLIDESHOW  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Animate
--------------------------------------------------------------*/



class Slideshow {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor (figure, delay) {
		this.figure = figure;
		this.length = figure.children.length;
		this.delay = delay;
		this.index = 2;
		this.animate();
	}



	/*--------------------------------------------------------------
	-	Animate
	--------------------------------------------------------------*/
	animate () {
		let t = new Timeout(this.delay, () => {
			this.figure.className = 'slideshow slide' + this.index;
			this.index = (this.index % this.length) + 1;
			t.reset();
			console.log('debug slideshows');
		});
	}



}
