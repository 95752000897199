/*--------------------------------------------------------------
FICHE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Constructor
- Bind
- Get Products
- Get Months
- Append
--------------------------------------------------------------*/



class Fiche {



	/*--------------------------------------------------------------
	-	Constructor
	--------------------------------------------------------------*/
	constructor ({id, producer_id, months, products, list}) {
		this.wrap = $$.id(id);
		this.producer_id = this.wrap.get(producer_id).value;
		this.months_droplist = this.wrap.get(months);
		this.months_input = this.months_droplist.get('input');
		this.products_droplist = this.wrap.get(products);
		if (this.products_droplist) {
			this.products_input = this.products_droplist.get('input');
		}
		this.list = this.wrap.get(list);

		// Liste des mois disponibles
		this.months = {};
		this.months_droplist.getAll('li').foreach(li => {
			this.months[li.getAttribute('data-id')] = li.textContent;
		});

		this.bind();
	}



	/*--------------------------------------------------------------
	-	Bind
	--------------------------------------------------------------*/
	bind () {
		this.months_input.onclick = _ => this.open(this.months_droplist);
		if (this.products_droplist) {
			this.products_input.onclick = _ => this.open(this.products_droplist);
		}
	}



	/*--------------------------------------------------------------
	-	Open
	--------------------------------------------------------------*/
	open (droplist) {
		droplist.classList.add('open');

		// Empty list
		this.list.removeChildren();

		// close droplist
		const close = _ => {
			droplist.classList.remove('open');
			window.removeEventListener('click', close);
		};

		// blur from droplist -> close droplist
		const blur = e => {
			if (!droplist.contains(e.target)) {
				close();
			}
		}
		window.addEventListener('click', blur);

		// click on li -> close droplist and get things
		droplist.getAll('li').foreach(li => {
			li.onclick = _ => {
				close();
				let id = li.getAttribute('data-id');
				if (droplist.classList.contains('months')) {
					this.get_products(id, li.textContent);
				} else {
					this.get_months(id, li.textContent);
				}
			};
		});
	}



	/*--------------------------------------------------------------
	-	Get Products
	--------------------------------------------------------------*/
	get_products (month_id, month_name) {

		// change value
		this.months_input.value = month_name;
		if (this.products_droplist) {
			this.products_input.value = '';
		}

		let query = `query ($month: Int, $producer: String) {
			products (month: $month, producer: $producer) {
				name {
					fr
					en
				}
			}
		}`;
		let variables = {
			month: month_id,
			producer: this.producer_id,
		};
		ajax.graphql({query, variables}).then(({data: {products}}) => {
			let list = [];
			let lang = document.documentElement.lang;
			products.foreach(product => {
				list.push(product.name[lang]);
			});
			this.append(list);
		});
	}



	/*--------------------------------------------------------------
	-	Get Months
	--------------------------------------------------------------*/
	get_months (product_id, product_name) {

		// change value
		this.products_input.value = product_name;
		this.months_input.value = '';

		let limited_txt = {
			fr: 'limitée',
			en: 'limited',
		};
		let query = `query ($id: String!) {
			product (id: $id) {
				availability {
					month
					limited
				}
			}
		}`;
		let variables = {
			id: product_id,
		};
		ajax.graphql({query, variables}).then(({data: {product: {availability: avails}}}) => {
			let list = [];
			let lang = document.documentElement.lang;
			avails.foreach(({month, limited}) => {
				let txt = this.months[month];
				if (limited) {
					txt += ` <span>/ ${limited_txt[lang]}</span>`;
				}
				list.push(txt);
			});
			this.append(list);
		});
	}



	/*--------------------------------------------------------------
	-	Append
	--------------------------------------------------------------*/
	append (list) {
		list.foreach(el => {
			let li = document.createElement('li');
			li.innerHTML = el;
			this.list.appendChild(li);
		});
	}



}
