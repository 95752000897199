/*--------------------------------------------------------------
ADELIOS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- rAF
- $$
- Prototypes
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	rAF
--------------------------------------------------------------*/
window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;



/*--------------------------------------------------------------
	$$
--------------------------------------------------------------*/
const $$ = {
	id: (el) => document.getElementById(el),
	first: (el) => document.body.querySelector(el),
	all: (el) => document.body.querySelectorAll(el),
	tags: (el) => document.getElementsByTagName(el),
};



/*--------------------------------------------------------------
	Prototypes
--------------------------------------------------------------*/
HTMLElement.prototype.transform = function (style) {
	this.style.transform = this.style.webkitTransform = style;
};
HTMLElement.prototype.tags = function (el) {
	return this.getElementsByTagName(el);
};
HTMLElement.prototype.get = function (el) {
	return this.querySelector(el);
};
HTMLElement.prototype.getAll = function (el) {
	return this.querySelectorAll(el);
};
HTMLElement.prototype.delete = function() {
	this.parentNode.removeChild(this);
};
HTMLElement.prototype.removeChildren = function () {
	while(this.firstChild)
		this.removeChild(this.firstChild);
};
HTMLElement.prototype.on = function (e, func) {
	this.addEventListener(e, func);
};
HTMLElement.prototype.off = function (e, func) {
	this.removeEventListener(e, func);
};
window.on = function (e, func) {
	window.addEventListener(e, func);
};
window.off = function (e, func) {
	window.removeEventListener(e, func);
};
Array.prototype.foreach = function (func) {
	let i = this.length;
	while (i--) {
		func(this[i], i, this);
	}
};
HTMLCollection.prototype.foreach = Array.prototype.foreach;
NodeList.prototype.foreach = Array.prototype.foreach;
